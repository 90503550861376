/* @import url('https://rsms.me/inter/inter.css'); */
/* .btn-nw-gray {
    background-color:
} */
@import '~antd/dist/antd.less';

//icons
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://use.fontawesome.com/releases/v6.0.0/css/all.css');
//fonts
//@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');
@import url('https://use.typekit.net/yyk5mpk.css');
//tools
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-image-editor/styles/bootstrap5.css";

@netwalk-font-base-size: 15px; //also REM

@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
@font-family-monospace: 'Chivo Mono', monospace;

@font-size-base: @netwalk-font-base-size;

@font-variant-base: none;
@font-feature-settings-base: 'ss01', 'case'on;

@text-color: rgba(0, 0, 0, 0.75);
@link-color: rgba(0, 0, 0, 1);
@text-color-muted: rgba(0, 0, 0, 0.5);
@text-color-super-muted: rgba(0, 0, 0, 0.25);

@primary-color: #26b3ea;
@danger-color: #f5222d;
@warning-color: #fadb14;
@background-color-light: #fff;
@layout-body-background: #f0f0f0;
@layout-header-background: #fff;
@layout-footer-background: #fff;
@layout-sider-background: #fff;

@lighter-border-color: #f0f0f0;

@layout-header-height: 60px;
@layout-header-padding: 0;
@layout-footer-padding: 0;
@btn-text-shadow: none;
@border-radius-base: 4px;
@btn-height-base: 28px;
@btn-height-lg: 34px;
@btn-height-sm: 24px;
@input-padding-vertical-base: 2px;
@input-height-base: 28px;
@input-height-lg: 34px;
@input-height-sm: 24px;
@btn-padding-horizontal-base: 12px;
@btn-padding-horizontal-lg: 16px;
@btn-padding-horizontal-sm: 8px;

@screen-xs: 480px;
@screen-sm: 1000px;
@screen-md: 1200px;
@screen-lg: 1400px;
@screen-xl: 1600px;
@screen-xxl: 1800px;

@default-border: 1px solid #e8e8e8;

html {
    font-size: @netwalk-font-base-size;
}


.nw-page {

    .nw-container {
        min-height: calc(100vh - 60px);

        @media (max-width: 720px) {
            padding: 0 .5rem;

            .ant-row {
                margin-left: 0 !important;
                margin-right: 0 !important;

                .ant-col {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }

    .nw-cards-grid>.ant-row>.ant-col,
    .nw-cards-grid>.ant-row-flex>.ant-col {
        margin-bottom: 12px;
    }

    .ant-list-items {
        .ant-list-item {
            .ant-list-item-top {
                align-items: flex-start;
            }

            // .ant-list-item-meta {
            // }
            .ant-list-item-action {
                >li {
                    color: @text-color;
                }
            }
        }
    }


    .no_events {
        pointer-events: none;
        opacity: 0.5;
    }

    .events {
        cursor: pointer;
    }

    .ant-page-header-ghost {
        background-color: white !important;
    }

    .package-model-draggable {
        z-index: 2100;
    }
}

@import 'Styles/_alerts.less';
@import 'Styles/_buttons.less';
@import 'Styles/_drawer.less';
@import 'Styles/_dropdowns.less';
@import 'Styles/_segmented.less';
@import 'Styles/_ellipsis.less';
@import 'Styles/_forms.less';
@import 'Styles/_links.less';
@import 'Styles/_menus.less';
@import 'Styles/_modals.less';
@import 'Styles/_nav.less';
@import 'Styles/_notifications.less';
@import 'Styles/_pagination.less';
@import 'Styles/_search.less';
@import 'Styles/_text.less';
@import 'Styles/_tooltips_popovers.less';
@import 'Styles/_toastui-image-editor.less';
@import 'Styles/_print_utils.less';

//@import 'Styles/_print_statement.less';
