.nw-page {
    .ant-btn:hover,
    .ant-btn:focus {
        /* border-color:  rgba(0,0,0,0.25); */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
    }

    .ant-radio-button-wrapper {
        span {
            text-transform: uppercase;
        }
    }
}