.nw-page {
    .upper {
        text-transform: uppercase;
    }
    .muted {
        color: @text-color-muted;
    }
    .super-muted {
        color: @text-color-super-muted;
    }
    .ant-typography {
        margin-bottom: .3em !important;
    }

    p.nw-code-block {
        font-size: .8rem;
        font-size: @font-family-monospace;
        background-color: @layout-body-background;
        padding: 1rem;
        border: @default-border;
        word-break: break-all;
    }
}