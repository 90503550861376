.nw-page {
    .ant-menu-inline {
        border-right: none;

        .ant-menu-item {
            margin: 0 !important;
        }

        .ant-menu-item-selected {
            color: black;
            font-weight: bold;
            background-color: white !important;
        }

        .ant-menu-submenu-title {
            margin: 0 !important;
            // border-bottom: 1px solid lightgrey;
        }

        .ant-menu-item-only-child {
            border-bottom: none;
        }

        .ant-menu-item-disabled {
            color: rgba(0, 0, 0, 0.75) !important;
            border-bottom: 1px solid lightgrey;
            background: #f0f0f0;
            font-size: .85rem;
            height: 32px !important;
        }

        .ant-menu-selected::after,
        .ant-menu-item-selected::after {
            display: none;
        }

        .ant-menu-submenu-selected .ant-menu-submenu-title {
            color: black;
            font-weight: bold;
        }

        .ant-menu-item:hover,
        .ant-menu-item-active,
        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: black;
        }
    }

    .menu-inline {
        .ant-menu-item {
            border-bottom: 1px solid lightgrey !important;
        }
    }

    .ant-menu-overflow-item::after {
        display: none;
    }
}