.nw-page {
    .ant-segmented {
        background-color: rgba(0,0,0,0.08);
        transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

        .ant-segmented-group {
            .ant-segmented-item {
                transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
                
                .ant-segmented-item-input {

                }
                .ant-segmented-item-label {
                    font-size: .9rem;
                    text-transform: uppercase;
                    opacity: .75;
                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none; /* Standard syntax */
                }

                &.ant-segmented-item-selected {
                    .ant-segmented-item-label {
                        font-weight: 500;
                        opacity: 1;
                    }
                }
            }
        }

        &.ant-segmented-sm {
            .ant-segmented-group {
                .ant-segmented-item {
                    .ant-segmented-item-label {
                        font-size: .75rem;
                    }
                }
            }
        }
        &.ant-segmented-lg {
            .ant-segmented-group {
                .ant-segmented-item {
                    .ant-segmented-item-label {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}