.nw-page {
    .nw-popover {
        max-width: 800px;

        .nw-popover-danger {
            .ant-popover-inner {
                background-color: @danger-color;
                .ant-popover-title {
                    color: white;
                    border-bottom-color: #fff;
                }
                .ant-popover-inner-content {
                    color: white;
                }
            }
        }
    }

    .ant-tooltip {
        max-width: 40rem;
        font-size: 1rem;

        .ant-tooltip-content {
            .ant-tooltip-inner {
                background-color: #595959;
                padding: .5rem 1rem;
                // color: @text-color;
            }

            .ant-tooltip-arrow {
                color: #595959;

                .ant-tooltip-arrow-content {
                    --antd-arrow-background-color: #595959;
                }
            }
        }

        &.ant-slider-tooltip {
            z-index: 4;

            &.ant-tooltip-placement-top {
                padding-bottom: 0;
            }

            .ant-tooltip-content {
                .ant-tooltip-arrow {
                    //margin-bottom: -8px;
                    width: 16px;
                    height: 16px;
                }

                .ant-tooltip-inner {
                    white-space: nowrap;
                    min-width: unset;
                    padding: 0.25rem 0.75rem !important;
                    font-size: .65rem;
                    line-height: 1;
                    min-height: 18px;
                }
            }
        }
    }

    .event-card-popover {
        .ant-popover-inner-content {
            padding: 0;
        }
    }

    .folder-popover {
        .ant-popover-title {
            padding: .5rem 1rem;
            font-weight: normal;
            font-size: .75rem;
            opacity: .5;
            text-transform: uppercase;
        }

        .ant-popover-inner-content {
            padding: 0;
        }

        .ant-menu-item-group-title {
            text-transform: uppercase;
            font-weight: 200;
            font-size: .8rem;
        }
    }

    .recipients-popover {
        .ant-popover-arrow-content {
            background-color: #595959;
        }

        .ant-popover-inner {
            background-color: #595959;
        }
    }

    .ant-popover {
        z-index: 1041;
    }

    .confirm-on-dropdown {
        z-index: 2000 !important;
    }
}