.nw-page {
    .ant-form-item-with-help {
        .ant-form-item-explain {
            min-height: 0 !important;
        }
    }

    .ant-form {
        .ant-form-item-label {
            small {
                display: block;
            }
        }

        .ant-input {
            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
                -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
                -webkit-text-fill-color: #666 !important;
            }

            //Auto fill text style
            &:-webkit-autofill::first-line {
                font-size: 17px;
            }
        }

        .has-error {
            .ant-input-group {
                .ant-btn {
                    border-color: @danger-color;
                    color: @danger-color;
                }
            }
        }
    }

    //enlarge the x icon to clear the input
    .ant-input-suffix {
        .ant-input-clear-icon {
            .anticon-close-circle {
                vertical-align: -0.25em;
                
                svg {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }

    .ant-legacy-form-item-control {
        line-height: none;
    }

    .ant-legacy-form-item-control-wrapper {
        width: 100%;
    }
}