.nw-page {
    .global-search-dropdown {
        left: 0 !important;
        border-radius: 0;
        top: 60px !important;
        box-shadow: 1px 1px 1px rgb(57 73 76 / 35%) !important;
        padding: 0;
        position: fixed;
    }

    .mobile-search-dropdown {
        left: 0 !important;
        border-radius: 0;
        top: 120px !important;
        box-shadow: 1px 1px 1px rgb(57 73 76 / 35%) !important;
        padding: 0;
    }

    .package-search-dropdown {
        border-radius: 0;
        box-shadow: 1px 1px 1px rgb(57 73 76 / 35%) !important;
        padding: 0;
    }

    .open-search-dropdown {
        border-radius: 0;
        //box-shadow: 1px 1px 1px rgb(57 73 76 / 35%) !important;
        padding: 0;
    }
}