.nw-page {
    .ant-drawer {
        &.mobile-nav-drawer {
            .ant-drawer-content-wrapper {
                .ant-drawer-content {
                    .ant-drawer-wrapper-body {
                        .ant-drawer-header {
                            padding: 0;
                            min-height: 60px;
                            display: block;
                        }
                        .ant-drawer-body {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}