@page {
    margin: 3rem 4rem;
}

@media print {
    html {
        font-size: 8pt;
        color: #000 !important;
    }

    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }

    small {
        font-size: .8rem;
    }
}

.printable-content-wrapper {
    margin: 1rem;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}

.hidden-print-header {
    //display: none;
    text-align: center;
    padding-bottom: 3rem;

    tr {
        td {
            page-break-inside: avoid;
        }

        th {
            page-break-inside: avoid;
        }
    }
}

.printable-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: @default-border;
    padding-bottom: 1rem;
    .printable-description {
        flex: 1 1 0;
        small {
            display: block;
            font-size: .85rem;
            text-transform: uppercase;
            font-weight: 200;
        }

        h2 {
            margin: 0 0 .25rem 0;
            font-size: 1.25rem;
            padding: 0;
            line-height: 1.1em;
            text-transform: capitalize;
        }
    }

    .printable-additional-data {
        display: block;
    }
}

.printable-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: @default-border;

    .printable-bottom-left {
        flex: 1 1 0;
    }

    .printable-bottom-additional-data {
        display: block;
    }
}

.print-body {
    display: block;

    .hidden-print-header {
        display: block;
    }

    .ant-table {
        font-size: 1rem;

        .ant-table-content {
            table {
                width: 100%;
            }
        }
    }
}