.nw-page {

    .nav-icon-wrapper {
        width: 1.5rem;
        text-align: center;
        display: inline-block;
    }

    .main-nav-submenu {
        .ant-menu-sub {
            padding: 12px 6px;
            min-width: 15rem;

            .ant-menu-item {
                margin: 3px 0;
                line-height: 1.5rem;
                //height: 1.5rem;
                //overflow: hidden;
                height: auto;
                text-overflow: ellipsis;
                color: #000000;
                font-size: .9rem;
                font-weight: normal;
                text-transform: uppercase;
                padding: 0 .75rem;

                &:hover {
                    background-color: #595959;
                    color: #fff;
                }

                .nw-nav-badge {
                    float: right;
                    color: #fff;
                    background-color: @danger-color;
                    padding: .2em .4em;
                    font-size: .75rem;
                    font-weight: 700;
                    line-height: 1;
                    text-align: center;
                    white-space: nowrap;
                    margin-top: .6em;
                    border-radius: .25rem;
                    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                }

                &.sublist {
                    font-size: .7em;
                    border-bottom: 1px solid #e8e8e8;
                    padding-left: 3em;
                    height: 1.2rem;
                    line-height: 1.2rem;
                }

                &.small {
                    font-size: .8rem;
                    height: 1.2rem;
                    line-height: 1.2rem;

                    .nw-nav-badge {
                        padding: .3em .4em;
                        font-size: .75rem;
                        margin-top: .3em;
                        border-radius: .15rem;
                    }
                }

                .last-seen-item {
                    div {
                        height: 1.1rem;
                        overflow: hidden;
                    }
                    small {
                        height: .9rem;
                        font-size: .8rem;
                        overflow: hidden;
                        font-weight: 200;
                    }
                }
            }

            .ant-menu-item-selected {
                background-color: @primary-color;
                //font-weight: 600;
                color: #fff;
            }

            .ant-menu-item-group {
                .ant-menu-item-group-title {
                    padding: 6px 12px;
                    font-size: .75rem;
                    text-transform: uppercase;
                    border-bottom: 1px solid #e8e8e8;
                }

                .submenu-title-wrapper {
                    display: flex;
                    align-items: center;
                }
            }

            svg {
                vertical-align: middle;
                margin-right: .5rem;
            }
        }

        &.menu-disabled {
            ul.ant-menu-sub {
                display: none;
            }
        }

        &.menu-double {
            ul.ant-menu-sub {
                display: flex;
                flex-wrap: wrap;
                max-width: 400px;

                @media (min-width: 1600px) {
                    max-width: 600px;
                }

                .ant-menu-item-group {
                    flex: 1 1 0;
                    max-width: 190px;
                    min-width: 190px;

                    @media (min-width: 1600px) {
                        max-width: 290px;
                        min-width: 290px;
                    }

                    .ant-menu-item-only-child {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:nth-child(2n) {
                        border-right: 1px solid lightgrey;
                        padding-right: 8px;
                    }

                    &:nth-child(2n+1) {
                        padding-left: 8px;
                    }
                }
            }
        }
    }

    .main-nav-inline {
        border-bottom: 1px solid lightgrey !important;

        .ant-menu-submenu-title {
            padding-left: 8px !important;
        }

        .ant-menu-sub {
            .ant-menu-submenu-title {
                padding-left: 20px !important;
            }

            .ant-menu-item-only-child {
                height: 24px !important;
                padding-left: 48px !important;
                border-bottom: none !important;
                margin-bottom: 8px !important;
            }

            .ant-menu-item-disabled {
                padding-left: 8px !important;
                margin-bottom: 8px !important;
            }

            .empty-menu-item {
                background-color: white !important;
                padding-left: 48px !important;
            }
        }
    }
}