.nw-page {
    .ant-select-dropdown {
        min-width: 18rem !important;
    }

    .ant-select-dropdown-areaselect {
        min-width: 10rem !important;
        .ant-select-item {
            &.ant-select-item-option {
                .ant-select-item-option-content {
                    font-size: .8rem !important;
                }
            }
        }
    }

    .ant-select {
        box-shadow: none !important;

        .ant-select-selection:focus,
        .ant-select-selection:hover {
            /* box-shadow: none !important; */
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
            border-color: rgba(0, 0, 0, 0.25);
        }
    }

    //overlay class for NwDropDownMenu.js
    .nw-dropdown-menu {
        min-width: 10rem;
        
        .ant-dropdown-menu-item-group-list {
            list-style-type: none;
            max-height: 7rem;
            overflow: auto;
            padding: 0;
        }

        .ant-dropdown-menu-item  {
            text-transform: uppercase;
            font-size: .9rem;
            vertical-align: middle;
            border-bottom: @default-border;

            &:hover {
                background-color:  @primary-color;
                color: #fff;
            }

            &:last-child {
                border-bottom: none;
            }

            .menu-item-icon-container {
                width: 30px;
                text-align: center;
                display: inline-block;
            }
        }
    }

    .nw-mobile-filters {
        min-width: 200px;

        div.nw-mobile-filters-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid @lighter-border-color;
            padding: 0 8px;
            margin: 1px 0;
            cursor: pointer;
            
            &:hover {
                box-shadow: 0 1px 3px rgba(57,73,76,.35);
            }

            &.no-borders {
                border: none;
                &:hover {
                    box-shadow: none;
                }
            }

            div.nw-mobile-filters-item-wrapper {
                display: flex;
                
                article {
                    margin-top: .3em;
                }
                
                &.active {
                    article {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
