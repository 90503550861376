.nw-page {
    .tui-image-editor-container {

        .tie-btn-delete {
            display: none !important;
        }

        .tie-btn-deleteAll {
            display: none !important;
        }
    

        .tui-image-editor-controls {
            width: 90px;
            background-color: @primary-color;

            .tui-image-editor-menu { 
                .tui-image-editor-item {
                    padding: 10px 8px;

                    svg.svg_ic-menu {
                        width: 42px;
                        height: 30px;

                        > use {
                            &.normal {
                                fill: #fff;
                                stroke: #fff;
                            }
                            &.active {
                                fill: @text-color;
                                stroke: @text-color;
                            }
                            &.hover {
                                fill: #fff;
                                stroke: #fff;
                            }
                        }
                    }
                }
            }
        }

        .tui-image-editor-main-container {
            left: 90px;
            width: calc(100% - 90px);
            border: none;
            border-bottom: @default-border;

            .tui-image-editor-header {
                .tui-image-editor-header-logo {
                    float: right;
                    padding: .5rem 1rem;
                    width: auto;
                    img {
                        width: 160px !important;
                        height: auto !important;
                        //gray filter
                        filter: grayscale(100%);
                    }
                }

                .tui-image-editor-header-buttons {
                    display: none;
                }
            }

            .tui-image-editor-main.tui-image-editor-menu-filter {
                .tui-image-editor-submenu {
                    .tui-image-editor-menu-filter {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding-left: 2rem;
                        height: 100%;

                        ul.tui-image-editor-submenu-item {
                            > li {
                                display: none;
                                
                                &:first-of-type {
                                    display: block;
                                    .tui-image-editor-checkbox-wrap {
                                        .tui-image-editor-checkbox {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}