.nw-page {
    .ant-modal-mask {
        z-index: 2000 !important;
    }

    .ant-modal-wrap {
        z-index: 2001 !important;

        &.nw-modal-ellipsis {
            white-space: pre-line;

            .ant-modal {
                max-width: 960px !important;
                width: 80% !important;
            }
        }

        &.duplicate-contacts-modal {
            .ant-modal-confirm-body {
                .ant-modal-confirm-content {
                    margin-left: 1rem !important;
                }
            }

            .ant-modal-confirm-btns {
                float: none;
                display: flex;
                justify-content: space-between;

                button {
                    svg {
                        margin-right: .5rem;
                    }
                }
            }
        }

        .ant-modal-body {
            .ant-modal-confirm-title {
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: 1rem;
            }
        }
    }
}