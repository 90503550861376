.nw-page {
    .ant-pagination-item-active {
        border-color: @primary-color;
        background-color: @primary-color;
        border-radius: 50%;

        a {
            color: white !important;
        }
    }

    .ant-pagination-item-active:focus a,
    .ant-pagination-item-active:hover a {
        color: white;
    }
}