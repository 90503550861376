.nw-page {
    .nw-link {
        text-decoration: underline;
        color: @primary-color;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: @primary-color;
        }

        &.disabled {
            color: #bfbfbf;
            pointer-events: none;
        }
    }
}