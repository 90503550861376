.nw-page {
    .ant-notification {
        z-index: 1050 !important;
        //z-index: 1038;
        top: 1rem;

        &.ant-notification-bottomRight, &.ant-notification-bottomLeft {
            top: auto;
            bottom: 0;
        }

        .ant-notification-notice {
            
            width: 25rem;
            max-width: calc(100vw - 3rem);
            margin-bottom: 1rem;
            padding: 1.5rem 1rem;
            overflow: hidden;
            line-height: 1.25;

            .ant-notification-notice-content {
                .ant-notification-notice-description {
                    max-height: calc(100vh - 8rem);
                    overflow: hidden;
                }

                .ant-notification-notice-icon {
                    padding-top: .5rem;
                }
            }
            
            &.nw-notification-contact-alert {
                .ant-notification-notice-content {
                    .ant-notification-notice-description {
                        margin: 1rem 0 0 .5rem; 
                    }
    
                    .ant-notification-notice-icon {
                        padding-top: .75rem;
                    }

                    .ant-notification-notice-message {
                        padding-top: .75rem;
                        text-transform: uppercase;
                        font-size: @font-size-sm;
                        color: @text-color-muted;
                    }
                }
            }

            &.ant-notification-notice-error {
                border-bottom: 4px solid @danger-color;

                .ant-notification-notice-icon {
                    color: @danger-color;
                }
            }

            &.ant-notification-notice-warning {
                border-bottom: 4px solid @warning-color;

                .ant-notification-notice-icon {
                    color: @warning-color;
                }
            }
        }

        
    }

    // .nw-small-notification {
    //     padding: 8px 12px;
    //     width: 500px;

    //     .ant-notification-notice-content {
    //         .ant-notification-notice-message {
    //             display: none;
    //         }

    //         .ant-notification-notice-description {
    //             padding-right: 24px;
    //             font-size: .85rem;
    //             line-height: 1.1em;
    //             min-height: 30px;
    //         }
    //     }

    //     .ant-notification-notice-close {
    //         top: 12px;
    //         right: 12px;

    //         .ant-notification-close-x {
    //             .ant-notification-close-icon {
    //                 font-size: 1.3rem;
    //             }
    //         }
    //     }

    // }
}